import React, { useContext, useState } from 'react'
import { API } from 'aws-amplify'
import { GlobalStateContext } from '../../context/GlobalContextProvider'
import { IconButton, Menu, MenuItem } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'

export default function EditEmployeeTag({
  tags,
  setEditEmployeeInfo,
  editEmployeeInfo,
}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton size='small' onClick={handleClick}>
        <AddCircleIcon htmlColor={'gray'} />
      </IconButton>
      <Menu
        elevation={1}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        MenuListProps={{ disablePadding: true }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {tags &&
          tags
            .filter(
              (i) =>
                !(
                  i.access.includes('権限なし') ||
                  i.tag === 'オーナー' || //owner
                  // (gLoginEmployee.loginInfo.access.includes('ロール管理')
                  //   ? ''
                  //   : i.tag === 'マネージャー') || // 有管理權限，才能新增manager角色
                  editEmployeeInfo.tag.includes(i.tag)
                )
            )
            .map((i) => {
              return (
                <MenuItem
                  key={i.tag}
                  onClick={() => {
                    setAnchorEl(null)
                    let newTag = [...editEmployeeInfo.tag, i.tag]

                    setEditEmployeeInfo({
                      ...editEmployeeInfo,
                      tag: newTag,
                    })
                    // API.patch('Attendance', `/employee/tag`, {
                    //   body: {
                    //     companyID: gStripe.customer.id,
                    //     subUUID,
                    //     tag: [...rowTags, i.tag],
                    //   },
                    // })
                    //   .then(() => {
                    //     gCompanyEmployee.getEmployees(
                    //       gStripe.customer.id,
                    //       gLoginEmployee.loginInfo.access
                    //     )
                    //   })
                    //   .catch((err) => console.log(err))
                  }}
                >
                  {i.tag}
                </MenuItem>
              )
            })}
      </Menu>
    </>
  )
}

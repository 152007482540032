import React, { useContext, useState, useEffect } from 'react'
import { GlobalStateContext } from '../../context/GlobalContextProvider'
import DeleteIcon from '@mui/icons-material/Delete'
import { Menu, MenuItem, IconButton, ListItemText } from '@mui/material'
import Hider from '../utils/Hider'

export default function DeleteItem({ handleCheck, employeeRole }) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const { gLoginEmployee } = useContext(GlobalStateContext)
  const [roleAction, setRoleAction] = useState(true)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  // const role = (role) => {
  //   switch (role) {
  //     case 'Owner':
  //       setRoleAction(employeeRole === 'Owner')
  //       break
  //     case '經理':
  //       setRoleAction(employeeRole === 'Owner')
  //       break
  //     case 'hr':
  //       setRoleAction(employeeRole === 'Owner' || employeeRole === '經理')
  //   }
  // }
  // useEffect(() => {
  //   if (gLoginEmployee.loginInfo.role) {
  //     // role(gLoginEmployee.loginInfo.role)
  //     if (gLoginEmployee.loginInfo.role === 'hr') {
  //       setRoleAction(employeeRole === 'Owner' || employeeRole === '經理')
  //     } else {
  //       setRoleAction(employeeRole === 'Owner')
  //     }
  //   }
  // }, [gLoginEmployee.loginInfo.role])

  return (
    <>
      <Hider show={employeeRole === 'Owner'}>
        <IconButton size='small' disabled>
          <DeleteIcon sx={{ color: 'disabled' }} />
        </IconButton>
        <IconButton size='small' onClick={handleClick} color='inherit'>
          <DeleteIcon sx={{ color: 'error.main' }} />
        </IconButton>
      </Hider>
      <Menu
        elevation={1}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        MenuListProps={{ disablePadding: true }}
        id='customized-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleCheck()
            setAnchorEl(null)
          }}
        >
          <ListItemText primary='削除' />
        </MenuItem>
      </Menu>
    </>
  )
}

import React, { useEffect, useState, useContext } from 'react'
import Layout from '../../layouts'
import { Link } from 'gatsby'
import { API } from 'aws-amplify'
import { GlobalStateContext } from '../../context/GlobalContextProvider'
import MUIDataTable from 'mui-datatables'
import {
  Card,
  CardHeader,
  List,
  ListItem,
  Typography,
  CardContent,
  IconButton,
  Box,
  Button,
  TextField,
  FormLabel,
} from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import DeleteItem from '../../components/attendance/deleteItem'
import AccessCheck from '../../components/attendance/accessCheck'
import EmployeeRoleChip from '../../components/attendance/employeeRoleChip'
import Hider from '../../components/utils/Hider'
import EditEmployeeTag from '../../components/attendance/editEmployeeTag'
import EditRoundedIcon from '@mui/icons-material/EditRounded'

export default function EmployeesInfo({ location }) {
  const { gLoginEmployee, gCompanyEmployee } = useContext(GlobalStateContext)
  const [employeeInfo, setEmployeeInfo] = useState({})
  const [editEmployeeInfo, setEditEmployeeInfo] = useState({})
  const [employeeKeys, setEmployeeKeys] = useState([])
  const [tags, setTags] = useState([])
  const [edit, setEdit] = useState(false)

  useEffect(() => {
    if (gLoginEmployee.loginInfo.companyID && typeof window !== 'undefined') {
      getEmployeeKeys()
      getEmployeeInfo()
      getTags(gLoginEmployee.loginInfo.companyID)
    }
  }, [gLoginEmployee.loginInfo.companyID])

  const getTags = (companyID) => {
    API.get('Attendance', `/tag`, {
      queryStringParameters: { companyID },
    })
      .then((res) => {
        setTags(res)
      })
      .catch((err) => console.log(err))
  }

  const getEmployeeKeys = () => {
    API.get('Attendance', `/employee/key`, {
      queryStringParameters: {
        companyID: gLoginEmployee.loginInfo.companyID,
        subUUID: localStorage.getItem('currEmployee'),
      },
    })
      .then((res) => {
        setEmployeeKeys(res)
        // console.log('setEmployeeKeys', res)
      })
      .catch((err) => console.log(err))
  }

  const getEmployeeInfo = () => {
    API.get('Attendance', `/employee/info`, {
      queryStringParameters: {
        companyID: gLoginEmployee.loginInfo.companyID,
        subUUID: localStorage.getItem('currEmployee'),
      },
    })
      .then((res) => {
        setEmployeeInfo(res)
        setEditEmployeeInfo(res)
      })
      .catch((err) => console.log(err))
  }

  const title = {
    width: '180px',
    fontWeight: 'bold',
  }

  const infoItem = {
    display: 'flex',
    alignItems: 'center',
  }

  return (
    <Layout location={location}>
      <AccessCheck access="ユーザー">
        <Card sx={{ mb: '15px' }}>
          <Hider show={!edit}>
            <Box>
              <CardHeader
                title={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton size="small" component={Link} to="/employees">
                      <KeyboardArrowLeftIcon />
                    </IconButton>
                    <Typography variant="h2" sx={{ ml: '9px' }}>
                      {employeeInfo.employeeName}
                    </Typography>
                    <IconButton
                      sx={{ ml: '2px' }}
                      size="small"
                      onClick={() => {
                        setEdit(true)
                      }}
                    >
                      <EditRoundedIcon
                        fontSize="inherit"
                        sx={{ color: 'primary.main', fontSize: '14px' }}
                      />
                    </IconButton>
                  </Box>
                }
              />
              <CardContent>
                <List
                  disablePadding
                  sx={{
                    '> .css-1samsxy-MuiListItem-root': {
                      padding: '0px',
                    },
                  }}
                >
                  <ListItem sx={{ ...infoItem }}>
                    <Typography sx={{ ...title }}>メールアドレス</Typography>
                    {employeeInfo.employeeEmail}
                  </ListItem>
                  <ListItem sx={{ ...infoItem }}>
                    <Typography sx={{ ...title }}>サブUUID</Typography>
                    {employeeInfo.subUUID}
                  </ListItem>
                  <ListItem sx={{ ...infoItem }}>
                    <Typography sx={{ ...title }}>ユーザー権限</Typography>
                    {editEmployeeInfo.tag &&
                      editEmployeeInfo.tag.map((tag, index) => {
                        return (
                          <EmployeeRoleChip
                            key={index}
                            handleCheck={() => {
                              let newTag = [...editEmployeeInfo.tag]
                              newTag.splice(index, 1)
                              setEditEmployeeInfo({
                                ...editEmployeeInfo,
                                tag: newTag,
                              })
                            }}
                            label={tag}
                            rowChipLength={1}
                          />
                        )
                      })}
                  </ListItem>
                  <ListItem sx={{ ...infoItem }}>
                    <Typography sx={{ ...title }}>所属</Typography>
                    {employeeInfo.department === undefined
                      ? '未設定'
                      : employeeInfo.department}
                  </ListItem>
                  <ListItem sx={{ ...infoItem }}>
                    <Typography sx={{ ...title }}>電話番号</Typography>{' '}
                    {employeeInfo.phone === undefined
                      ? '未設定'
                      : employeeInfo.phone}
                  </ListItem>
                </List>
              </CardContent>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              <IconButton
                size="small"
                sx={{ mt: '3px' }}
                component={Link}
                to="/employees"
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
              <Box>
                <Box sx={{ ...infoItem }}>
                  <FormLabel sx={{ ...title, color: 'black' }} required>
                    ユーザー名
                  </FormLabel>
                  <TextField
                    sx={{ minWidth: '350px' }}
                    size="small"
                    required
                    value={editEmployeeInfo.employeeName}
                    onChange={(e) => {
                      setEditEmployeeInfo({
                        ...editEmployeeInfo,
                        employeeName: e.target.value.trim(),
                      })
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    ...infoItem,
                    mt: '10px',
                  }}
                >
                  <FormLabel sx={{ ...title, color: 'black' }}>
                    メールアドレス
                  </FormLabel>
                  <TextField
                    size="small"
                    value={employeeInfo.employeeEmail}
                    disabled={true}
                    sx={{ minWidth: '350px' }}
                  />
                </Box>
                <Box
                  sx={{
                    ...infoItem,
                    mt: '10px',
                  }}
                >
                  <Typography sx={{ ...title, color: 'black' }}>
                    ユーザー権限
                  </Typography>
                  {editEmployeeInfo.tag &&
                    editEmployeeInfo.tag.map((tag, index) => {
                      return (
                        <EmployeeRoleChip
                          key={index}
                          handleCheck={() => {
                            let newTag = [...editEmployeeInfo.tag]
                            newTag.splice(index, 1)
                            setEditEmployeeInfo({
                              ...editEmployeeInfo,
                              tag: newTag,
                            })
                          }}
                          label={tag}
                          rowChipLength={editEmployeeInfo.tag.length}
                        />
                      )
                    })}
                  {editEmployeeInfo.tag && (
                    <Hider
                      show={
                        editEmployeeInfo.tag &&
                        (editEmployeeInfo.tag.includes('オーナー') || //owner
                        editEmployeeInfo.tag.includes('ゲスト') || // guest
                          tags.length - editEmployeeInfo.tag.length - 2 === 0)
                      }
                    >
                      <></>
                      <EditEmployeeTag
                        tags={tags}
                        setEditEmployeeInfo={setEditEmployeeInfo}
                        editEmployeeInfo={editEmployeeInfo}
                      />
                    </Hider>
                  )}
                </Box>
                <Box
                  sx={{
                    ...infoItem,
                    mt: '10px',
                  }}
                >
                  <FormLabel sx={{ ...title, color: 'black' }}>所属</FormLabel>
                  <TextField
                    size="small"
                    value={editEmployeeInfo.department}
                    sx={{ minWidth: '350px' }}
                    onChange={(e) => {
                      setEditEmployeeInfo({
                        ...editEmployeeInfo,
                        department: e.target.value.trim(),
                      })
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    ...infoItem,
                    mt: '10px',
                  }}
                >
                  <FormLabel sx={{ ...title, color: 'black' }}>
                    電話番号
                  </FormLabel>
                  <TextField
                    sx={{ minWidth: '350px' }}
                    size="small"
                    value={editEmployeeInfo.phone}
                    onChange={(e) => {
                      setEditEmployeeInfo({
                        ...editEmployeeInfo,
                        phone: e.target.value.trim(),
                      })
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    mt: '10px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    size="small"
                    sx={{ mr: 1 }}
                    onClick={() => {
                      setEdit(false)
                      setEditEmployeeInfo(employeeInfo)
                    }}
                  >
                    キャンセル
                  </Button>
                  <Button
                    disableElevation
                    size="small"
                    disabled={editEmployeeInfo.employeeName === ''}
                    variant="outlined"
                    onClick={() => {
                      let info = {
                        ...editEmployeeInfo,
                        phone:
                          editEmployeeInfo.phone === ''
                            ? undefined
                            : editEmployeeInfo.phone,
                        department:
                          editEmployeeInfo.department === ''
                            ? undefined
                            : editEmployeeInfo.department,
                      }
                      delete info.access
                      gCompanyEmployee.setIsPending(true)
                      API.patch('Attendance', `/employee`, {
                        body: info,
                      })
                        .then((res) => {
                          gCompanyEmployee.getEmployees(
                            gLoginEmployee.loginInfo.companyID,
                            gLoginEmployee.loginInfo.access,
                            null,
                          )
                          setEdit(false)
                          setEmployeeInfo(editEmployeeInfo)
                        })
                        .catch((err) => console.log(err))
                    }}
                  >
                    確定
                  </Button>
                </Box>
              </Box>
            </Box>
          </Hider>
        </Card>

        <MUIDataTable
          title={
            <Typography variant="h2">
              {employeeInfo.employeeName}合鍵一覧
            </Typography>
          }
          data={employeeKeys}
          columns={[
            { name: 'deviceName', label: 'デバイス名' },
            { name: 'deviceUUID', label: 'UUID' },
            { name: 'deviceModel', label: 'デバイス種類' },
            {
              name: 'keyLevel',
              label: '合鍵権限',
              options: {
                customBodyRender: (value) => {
                  return (
                    <>
                      {value === 0
                        ? 'オーナー鍵'
                        : value === 1
                        ? 'マネージャー鍵'
                        : 'ゲスト鍵'}
                    </>
                  )
                },
              },
            },
            {
              name: 'endAt',
              label: '有効時間',
              options: {
                customBodyRenderLite: (dataIndex) => {
                  return (
                    <>
                      <Typography
                        variant="h5"
                        sx={{
                          color:
                            employeeKeys[dataIndex].endAt <
                              Math.floor(new Date().getTime() / 1000) ||
                            employeeKeys[dataIndex].startAt >
                              Math.floor(new Date().getTime() / 1000)
                              ? 'info.light'
                              : 'black',
                        }}
                      >
                        {employeeKeys[dataIndex].startAt === undefined
                          ? '常時利用'
                          : `${new Date(
                              employeeKeys[dataIndex].startAt * 1000,
                            ).toLocaleString()} ~ `}
                        {employeeKeys[dataIndex].endAt === undefined
                          ? undefined
                          : `${new Date(
                              employeeKeys[dataIndex].endAt * 1000,
                            ).toLocaleString()}`}
                      </Typography>
                    </>
                  )
                },
              },
            },
            {
              name: '削除',
              options: {
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                  return (
                    <DeleteItem
                      handleCheck={() => {
                        API.del('Attendance', `/employee/key`, {
                          body: {
                            subUUID: employeeInfo.subUUID,
                            deviceUUID: employeeKeys[dataIndex].deviceUUID,
                          },
                        })
                          .then(() => {
                            let tmp = [...employeeKeys]
                            tmp.splice(dataIndex, 1)
                            setEmployeeKeys(tmp)
                          })
                          .catch((error) => {
                            console.log(error.response)
                          })
                      }}
                    />
                  )
                },
              },
            },
          ]}
          options={{
            setTableProps: () => {
              return {
                fullWidth: true,
                size: 'small',
              }
            },
            setRowProps: (row, dataIndex) => {
              return {
                sx: {
                  '> td > div': {
                    color:
                      employeeKeys[dataIndex].endAt <
                      Math.floor(new Date().getTime() / 1000)
                        ? 'info.light'
                        : 'black',
                  },
                },
              }
            },
            filter: false,
            search: false,
            print: false,
            download: false,
            viewColumns: false,
            customToolbar: null,
            pagination: false,
            selectableRows: 'none',
            elevation: 0,
            textLabels: {
              body: {
                noMatch: '検索結果は０件です',
                toolTip: 'ソート',
                columnHeaderTooltip: (column) => `ソート ${column.label}`,
              },
            },
          }}
        />
        {/* </Hider> */}
      </AccessCheck>
    </Layout>
  )
}
